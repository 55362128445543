import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyAdN4bLUGmaZ9XOGNPrcILf8UcgPnpgNFQ",
  authDomain: "tiangewang-8cc9e.firebaseapp.com",
  projectId: "tiangewang-8cc9e",
  storageBucket: "tiangewang-8cc9e.appspot.com",
  messagingSenderId: "90066551843",
  appId: "1:90066551843:web:c62e848a0d45e4d8ce1d96"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { auth };