import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ErrorView from '../views/ErrorView.vue'
import { auth } from '../firebase'

const routes = [
  { path: '/', name: 'LandingView', component: () => import('../views/LandingView.vue') },
  { path: '/sora', name: 'SoraPublic', component: () => import('../views/projects/Sora.vue'), props: true, meta: {requiresAuth: false}},
  { path: '/elf', name: 'ElfPublic', component: () => import('../views/projects/Elf.vue'), props: true, meta: {requiresAuth: false}},

  { path: '/home',name: 'HomeView',component: HomeView, meta: {requiresAuth: true}},
  { path: "/work", name: "WorkView", component: () => import('../views/WorkView.vue'), meta: {requiresAuth: true} },
  { path: '/about',name: 'AboutView',component: () => import('../views/AboutView.vue'),meta: {requiresAuth: true}},
  
  { path: '/work/sora', name: 'sora', component: () => import('../views/projects/Sora.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/co-fridge', name: 'co-fridge', component: () => import('../views/projects/CoFridge.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/elf', name: 'elf', component: () => import('../views/projects/Elf.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/intuition-from-machine', name: 'intuition-from-machine', component: () => import('../views/projects/IntuitionFromMachine.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/under-the-influence', name: 'under-the-influence', component: () => import('../views/projects/UnderTheInfluence.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/viruscape', name: 'viruscape', component: () => import('../views/projects/Viruscape.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/sink-in-sync', name: 'sink-in-sync', component: () => import('../views/projects/SinkInSync.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/wilkinson-manual', name: 'wilkinson-manual', component: () => import('../views/projects/WilkinsonManual.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/tg-w', name: 'tg-w', component: () => import('../views/projects/Tgw.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/one-xsmart-kitchen', name: 'one-xsmart-kitchen', component: () => import('../views/projects/OneXSmartKitchen.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/kowloon-east-bluebird', name: 'kowloon-east-bluebird', component: () => import('../views/projects/KowloonEastBluebird.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/scent-ly', name: 'scent-ly', component: () => import('../views/projects/Scently.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/layered-cloud', name: 'layered-cloud', component: () => import('../views/projects/LayeredCloud.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/tales-of-a-porous-landscape', name: 'tales-of-a-porous-landscape', component: () => import('../views/projects/TalesOfAPorousLandscape.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/urban-interface', name: 'urban-interface', component: () => import('../views/projects/UrbanInterface.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/layered-permeability', name: 'layered-permeability', component: () => import('../views/projects/LayeredPermeability.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/machined-hybrids', name: 'machined-hybrids', component: () => import('../views/projects/MachinedHybrids.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/scattered-plots', name: 'scattered-plots', component: () => import('../views/projects/ScatteredPlots.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/host-tables-guest-bays', name: 'host-tables-guest-bays', component: () => import('../views/projects/HostTablesGuestBays.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/continuous-interior', name: 'continuous-interior', component: () => import('../views/projects/ContinuousInterior.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/greenline-gardens', name: 'greenline-gardens', ccomponent: () => import('../views/projects/GreenlineGardens.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/soft-by-hard', name: 'soft-by-hard', component: () => import('../views/projects/SoftByHard.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/live-work-campus', name: 'live-work-campus', component: () => import('../views/projects/LiveWorkCampus.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/on-water-then-in-air', name: 'on-water-then-in-air', component: () => import('../views/projects/OnWaterThenInAir.vue'), props: true, meta: {requiresAuth: true} },
  { path: '/work/space-v', name: 'space-v', component: () => import('../views/projects/SpaceV.vue'), props: true, meta: {requiresAuth: true} },


  { path: '/*',name: 'ErrorView',component: ErrorView, meta: {requiresAuth: true}}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes, 
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})


router.beforeEach((to, from, next) => {
  if (to.path === '/' && auth.currentUser) {
    next('/home')
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !auth.currentUser){
    next('/')
    return;
  }

  next();
})

export default router
