<template>
    <div class="nav-header row">
      <router-link class="logo-container col-4" to="/home">
        tg.w
      </router-link>
      <nav class="nav-container col-8">
        <ul class="tab-list">
          <li>
            <router-link to="/home" class="router-link">Home</router-link>
          </li>
          <li id="work-li">
            <router-link to="/work" class="router-link">Projects</router-link>
          </li>
          <!-- <li id="play-li">
            <router-link to="/play" class="router-link">Play</router-link>
          </li> -->
          <li>
            <router-link to="/about" class="router-link">About</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </template>
  
  <script>
  //import SearchBar from './SearchBar.vue';
  
  export default {
    name: 'MainNav',
    props: [],
    components: {},
    data() {
      return {
      };
    },
    methods:{
    }
  }
  </script>
  
  <style scoped>
  .nav-header{
    font-family: 'Montserrat', sans-serif;
    background-color: #f6f4f2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 12vh;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 2vh 1vw;
    z-index:99;
  }
  .logo-container{
    /* font-family: 'Montserrat', sans-serif; */
    font-size: 7vmin;
    font-size: 2.5rem;
    font-weight: 500;
    text-decoration: none;
    color: #5B43FA;
    display:flex;
    align-items: center;
    padding: 0 2vw;
  }
  .nav-header *{
    display: inline;
  }
  .nav-header li{
    margin: 1vw;
  }
  .nav-header li .router-link{
    /* font-family: 'Montserrat', sans-serif; */
    font-size: 0.95rem;
    font-weight: 400;
    color: #403D39;
    text-decoration: none;
    padding-bottom: 1vh;
    transition: 0.3s;
  }
  .nav-header li .router-link-active{
    font-weight: 700;
    color: #5B43FA;
    /* border-bottom: 3px solid #5B43FA; */
  }
  .nav-container{
    text-align: right;
    justify-content: right;
    align-items: flex-end;
  }
  .tab-list{
    padding: 0;
    margin: 0 1vw;
  }
  /* #work-li{
    margin-left: 2vw;
    margin-right: 1.5vw;
  }
  #play-li{
    margin-left: 1.5vw;
    margin-right: 2vw;
  } */
  @media (min-width: 760px) and (max-width: 1024px){
    .logo-container{
      font-size: 2rem;
      padding:0;
      margin:0;
    }
    .nav-header li .router-link{
      font-size: 0.95rem;
    }
    .nav-header li{
      margin: 20px;
    }
    .nav-header{
      padding: 0 2vw 0 5vw;
    }
  }
  @media (max-width: 760px){
    .logo-container{
      font-size: 2rem;
    }
    .nav-header li .router-link{
      font-size: 0.8rem;
    }
    .nav-header li{
      margin: 3vw;
    }
    .nav-header{
      padding: 0 6vw 0 6vw;
    }
  }
  @media (max-width: 320px){
    .logo-container{
      font-size: 2rem;
    }
    .nav-header li{
      margin: 1.8vw;
    }
  }
  </style>
  