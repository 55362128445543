import { createStore } from 'vuex'
import router from '../router'
import { auth } from '../firebase'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from '@firebase/auth'

export default createStore({
  state: {
    user: null
  },
  getters: {
  },
  mutations: {
    SET_USER (state, user) {
      state.user = user
    },
    CLEAR_USER (state) {
      state.user = null
    }
  },
  actions: {
    async login ({ commit }, details){
      let { email, password } = details
      if(password === "mono123"){
        email = 'xinfeng.manx@gmail.com'
      }else{
        email = 'isabel.tg.wang@gmail.com'
      }
      //email = 'isabel.tg.wang@gmail.com'

      try {
        await signInWithEmailAndPassword(auth, email, password)
      } catch (error) {
        switch(error.code){
          case 'auth/user-not-found':
            alert("User mot found")
            break
          default: 
            alert("Something went wrong")
        }

        return
      }

      commit('SET_USER', auth.currentUser)
      router.push('/home')
    },

    async register ({ commit }, details){
      const { email, password } = details

      try {
        await createUserWithEmailAndPassword(auth, email, password)
      } catch (error) {
        switch(error.code){
          case 'auth/email-already-in-use':
            alert("User mot found")
            break
          case 'auth/invalid-email':
            alert("Invalid email")
            break
          case 'auth/operation-not-allowed':
            alert("Operation not allowed")
            break
          case 'auth/weak-password':
            alert("Weak password")
            break
          default: 
            alert("Something went wrong")
        }

        return
      }

      commit('SET_USER', auth.currentUser)
      router.push('/home')
    },

    async logout ({ commit }){
      await signOut(auth)

      commit('CLEAR_USER')
      router.push('/')
    },

    fetchUser({ commit }) {
      auth.onAuthStateChanged(async user => {
        if (user === null) {
          commit('CLEAR_USER')
        } else {
          commit('SET_USER', user) 

          if (router.isReady() && router.currentRoute.value.path === '/') {
            router.push('/home')
          }
        }
      })
    }
  },
  modules: {
  }
})
