<template>
    <div class="error-div">
      <div class="row justify-content-center row-one">
        <MainNav /> 
      </div> 
      <div class="row justify-content-center row-two">
        <div class="col-12 notice-col">
          <span class="notice-text">
            OOPS!<br>
            <span id="invite">This page does not exist (yet?)</span>
            <div class="router-div">
              <router-link to="/home" class="router-link">Back to home</router-link>
            </div>
          </span>
        </div>
      </div>
    </div>
</template>
  
<script>
import MainNav from '../components/ui/MainNav.vue';

export default {
  name: 'ErrorView',
  components: {MainNav},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
  
  <style scoped>
  .error-div{
    font-family: 'Montserrat', sans-serif;
    height: 100vh;
    padding:0;
    margin:0;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    background-image: url('../assets/404.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
  }
  .row-one{
    height: 16vh;
    margin: 0;
    padding: 0;
  }
  .row-two{
    height: 84vh;
    margin: 0;
    padding: 0;
  }
  .nav-header{
    background-color: transparent;
  }
  .notice-col{
    position: relative;
    display: flex;
    padding: 4vw 0 4vw 5vw;
  }
  .notice-text{
    font-size: 4rem;
    font-weight: 400;
    color: #403D39;
    line-height: normal;
    align-self: flex-start;
  }
  #invite{
    font-weight: 700;
  }
  .router-div{
    margin: 3vh 0;
    transition: 0.3s;
  }
  .router-link{
    font-size: 1.5rem;
    padding: 1.5vh 1.5vw 1.5vh 1.5vw;
    background-color: #403D39;
    border-radius: 50px;
    border:none;
    color:white;
    text-decoration: none;
    height: 4vh;
    transition: 0.3s;
  }
  .router-link:hover{
    background-color: #5B43FA;
    color: white;
  }
  </style>
  