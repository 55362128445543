<template>
  <div class="home-div">
    <!-- <div v-if="auth === 'dearGuest'"> -->
      <div class="row justify-content-center row-one">
        <MainNav /> 
      </div> 
      <svg width="100%" height="100%">
          <rect x="0" y="0" width="100%" height="100%" fill="#f6f4f2"/>
          <circle cx="100vw" cy="100vh" r="80vh" fill="#EBFFC2"/>
      </svg>
      <div class="icon-div">
        <a href="https://www.linkedin.com/in/tiange-wang-2ab4b8b7/" target="_blank">
          <img src="../assets/icons/linkedin.png" class="icon">
        </a>
        <a href="https://github.com/617650" target="_blank">
          <img src="../assets/icons/github.png" class="icon">
        </a>
        <a href="https://www.instagram.com/not_isabelle/?hl=en" target="_blank">
          <img src="../assets/icons/instagram.png" class="icon">
        </a>
        <a href="mailto:isabel.tg.wang@gmail.com" target="_blank">
          <img src="../assets/icons/email.png" class="icon">
        </a>  
      </div>
      <div class="row justify-content-left text-row content-row">
        <div class="col-lg-9 col-md-12 text-col">
          <div class="text-div">
            <span id="hi">Welcome!</span><br>
            <span id="intro">I’m 
              <span id="strong">Tiange Wang</span>
              , a multidisciplinary designer creating human-centered and data-driven experiences across digital and physical platforms
              through 
              <img src="../assets/icons/desktop.svg" class="icon color-icon">software, 
              <img src="../assets/icons/intersect.svg" class="icon color-icon">interaction 
              and <img src="../assets/icons/robot.svg" class="icon color-icon">creative technology.
              <!-- <br><br>
              As a hybrid designer-engineer, I enjoy envisioning user experience futures as much as bringing them to life.  -->
              </span>
          </div>
          <div class="router-div">
            <div class="direction-btn"><a v-on:click="showResume" class="router-link" id="resume-link">Resume</a></div>
            <div class="direction-btn"><router-link to="/work" class="router-link" id="projects-link">Projects</router-link></div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import MainNav from '../components/ui/MainNav.vue';

export default {
  name: 'HomeView',
  components: {MainNav},
  data() {
    return {
      pdf: './documents/TiangeWang_Resume2023.pdf',
    };
  },
  methods: {
    showResume(){
      window.open(this.pdf);
    },
  },
};
</script>

<style scoped>
.home-div{
  font-family: 'Montserrat', sans-serif;
  height: auto;
  padding:0;
  margin:0;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color:#F6f4f2;
}
.direction-btn{
  display: inline-block;
}
svg{
  position: fixed;
  z-index: 0;
}
.nav-header{
  background-color: #F6f4f2;
  z-index: 99;
}
.prompt-link{
  color: #403D39;
  text-decoration: solid underline #EBFFC2 16px;;
}
.row-one{
  height: 16vh;
  margin: 0;
  padding: 0;
}
.icon-div{
  position: fixed;
  display: flex;
  right: 4vw;
  bottom: 4vw;
  height: 3vh;
  z-index: 99;
  /* padding-bottom: 4vw; */
}
.icon{
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  height: 3vh;
  transition: 0.3s;
}
.color-icon{
    height: 2.5rem;
}
.icon:hover{
  cursor: pointer;
  transform: rotate(-0.12turn);
}
.text-row{
  position: relative;
  height: 72vh;
  z-index: 0;
}
.text-col{
  height:72vh;
  padding: 8vh 8vw;
}
.feature-title-row{
  position: relative;
  height: 12vh;
  z-index: 0;
}
.feature-title-col{
  height: 12vh;
  padding-left: 8vw;
}
.text-div{
  /* font-family: 'Oxygen', sans-serif; */
  font-size: 1.3rem;
  color: #403D39;
}
#intro{
  font-size: 2rem;
  line-height: normal;
}
#strong{
  font-weight:700;
}
/* #guide{
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 2rem;
  color: #403D39;
} */
#feature{
  text-decoration: solid underline #EBFFC2 16px;
}
#feature-arrow{
  color: #5B43FA;
}
#feature:hover{
  cursor: crosshair;
}
.router-div{
  margin: 4vh 0;
  transition: 0.3s;
}
.router-link{
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0.8vh 1.2vw 0.8vh 1.2vw;
  padding: 10px 36px;
  background-color: #403D39;
  border-radius: 50px;
  border: 2px solid #403D39;
  color:white;
  text-decoration: none;
  height: 4vh;
  transition: 0.3s;
}
.router-link:hover{
  background-color: #5B43FA;
  color: white;
  cursor: pointer;
}
/* .guide-div{
  display: flex;
  height: auto;
  align-items: flex-end;
} */
.prompt-row{
  position: relative;
  height: 24vh;
  z-index: 66;
}
#resume-link{
  background-color: white;
  color: #403D39;
}
#resume-link:hover{
  background-color: #403D39;
  color: white;
  cursor: pointer;
}
#projects-link{
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  background-color: white;
  color: #403D39;
}
#projects-link:hover{
  background-color: #403D39;
  color: white;
  cursor: pointer;
}
@media (max-width: 760px){
  .text-div{
    font-size: 1rem;
  }
  #intro{
    font-size: 1.4rem;
  }
  .router-link{
    font-size: 0.9rem;
    padding: 0.8vh 8vw 0.8vh 8vw;
  }
  .content-row{
    padding: 0 3vw 0 3vw;
  }
  .footer-row{
    display:none;
  }
  #resume-link{
    margin-right: 10px;
  }
  #projects-link{
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 320px){
  .text-div{
    font-size: 1rem;
  }
  #intro{
    font-size: 1.4rem;
  }
  .router-link{
    font-size: 0.9rem;
    padding: 0.8vh 8vw 0.8vh 8vw;
  }
  #resume-link{
    margin-right: 10px;
  }
  #projects-link{
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (min-width: 760px) and (max-width: 1024px){
  .text-div{
    font-size: 1.2rem;
  }
  #intro{
    font-size: 1.7rem;
  }
  .router-link{
    font-size: 1.1rem;
    padding: 0.8vh 8vw 0.8vh 8vw;
    padding: 8px 36px;
  }
  #resume-link{
    margin-right: 10px;
  }
  #projects-link{
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>

